export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
export type ValuesOf<T extends any[]> = T[number]

export function assertNever(x: never, msg?: string): never {
  throw new Error(msg)
}

export function checkNever(x: never): void {
}

export function requireDefined<T>(item: T | undefined | null, message?: string): T {
  if (item == null) {
    throw new Error('Item can not be null or undefined' + (message ? (': ' + message) : ''))
  } else {
    return item
  }
}

export function isDefined<T>(item: T | undefined | null): item is T {
  return item != null
}

export function nullToUndefined<T>(val: T | null): T | undefined {
  return val === null ? undefined : val
}

export function undefinedToNull<T>(val: T | undefined): T | null {
  return val === undefined ? null : val
}

type TypeGuard<U, T extends U> = (x: U) => x is T
type TypeGuardUnionResultType<T extends TypeGuard<any, any>[]> = T extends TypeGuard<any, infer X>[] ? X : never
type TupleToUnion<T extends any[]> = T extends Array<infer U> ? U : never
type DiscriminatedType<U, K extends keyof U, V extends U[K]> = U extends any ? U[K] extends V ? U : never : never
type DiscriminatedTypeUnion<U, K extends keyof U, V extends U[K][]> = DiscriminatedType<U, K, TupleToUnion<V>>

function anyGuard<T extends TypeGuard<U, any>[], U>(...guards: T) {
  return (x: U): x is TypeGuardUnionResultType<T> => guards.some(guard => guard(x))
}

function createTypeGuard<U, K extends keyof U, V extends U[K]>(key: K, type: V): TypeGuard<U, DiscriminatedType<U, K, V>> {
  return (x: U): x is DiscriminatedType<U, K, V> => x[key] === type
}

export function isType<U, K extends keyof U, V extends U[K][]>(key: K, ...types: V): TypeGuard<U, DiscriminatedTypeUnion<U, K, V>> {
  return anyGuard(...types.map((v: any) => createTypeGuard<U, K, typeof v>(key, v)))
}

export function requireType<U, T extends U>(items: U[], typeGuard: TypeGuard<U, T>): T[] {
  return items.filter((x): x is T => {
    if (typeGuard(x)) {
      return true
    } else {
      console.error(x)
      throw new Error('Item is incompatible')
    }
  })
}

export type AllValues<T, A extends T[]> = [T] extends [A[number]] ? unknown : 'Does not contain all values'
export const requireAllValues = <T>() => <A extends T[]>(arr: A & AllValues<T, A>) => arr
